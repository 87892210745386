export enum PERFORMANCE_FILTERS {
  NAME = 'Name ',
  ICAO = 'ICAO Type Designator',
  WAKE_TURBULENCE_CATEGORY = 'Wake Turbulence Category',
  CLIMB_SCHEDULE = 'Default Climb Schedule',
  CRUISE_SCHEDULE = 'Default Cruise Schedule',
  DESCENT_SCHEDULE = 'Default Descent Schedule',
  HOLD_SCHEDULE = 'Default Hold Schedule',
  PERFORMANCE_LINK = 'Performance Manual',
  COMMENTS = 'Comments',
}
