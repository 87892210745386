import { RDBSpecsModel } from './RDBSpecs.model';
import {
  CoreModel,
  ISelectOption,
  Utilities,
  modelProtection,
  SettingsTypeModel,
  SelectOption,
} from '@wings-shared/core';
import { IAPIAirframe } from '../Interfaces';
import { AircraftVariationModel } from './AircraftVariration.model';

@modelProtection
export class AirframeModel extends CoreModel implements ISelectOption {
  startDate: string = '';
  endDate: string = '';
  aircraftVariationId: number = null;
  serialNumber: string = null;
  manufactureDate: string = '';
  temporaryEngineDate: string = '';
  crewSeatCap: number = null;
  paxSeatCap: number = null;
  genericAircraftCode: string = '';
  noiseChapter: SettingsTypeModel;
  airframeStatus: SettingsTypeModel;
  aircraftVariation: AircraftVariationModel;
  rdbSpecs: RDBSpecsModel;
  militaryDesignations: SettingsTypeModel[];
  airworthinessRecentDate: string = '';
  airworthinessCertificateDate: string = '';
  isVerificationComplete: SelectOption;

  constructor(data?: Partial<AirframeModel>) {
    super(data);
    Object.assign(this, data);
    this.noiseChapter = data?.noiseChapter ? new SettingsTypeModel(data?.noiseChapter) : null;
    this.airframeStatus = data?.airframeStatus ? new SettingsTypeModel(data?.airframeStatus) : null;
    this.aircraftVariation = data?.aircraftVariation ? new AircraftVariationModel(data?.aircraftVariation) : null;
    this.rdbSpecs = data?.rdbSpecs ? new RDBSpecsModel(data?.rdbSpecs) : null;
    this.militaryDesignations = data?.militaryDesignations?.map(a => new SettingsTypeModel(a)) || [];
  }

  static deserialize(apiData: IAPIAirframe): AirframeModel {
    if (!apiData) {
      return new AirframeModel();
    }
    const data: Partial<AirframeModel> = {
      ...apiData,
      ...CoreModel.deserializeAuditFields(apiData),
      noiseChapter: SettingsTypeModel.deserialize({
        ...apiData.noiseChapter,
        id: apiData.noiseChapter?.noiseChapterId,
      }),
      airframeStatus: SettingsTypeModel.deserialize({
        ...apiData.airframeStatus,
        id: apiData.airframeStatus?.airframeStatusId,
      }),
      militaryDesignations: apiData.airframeMilitaryDesignations?.map(a =>
        SettingsTypeModel.deserialize({ ...a.militaryDesignation, id: a.militaryDesignation?.militaryDesignationId })
      ),
      aircraftVariation: new AircraftVariationModel({ id: apiData.aircraftVariation?.aircraftVariationId }),
      rdbSpecs: RDBSpecsModel.deserialize(apiData?.rdbSpecs),
      isVerificationComplete: new SelectOption({
        value: apiData.isVerificationComplete,
        name: apiData.isVerificationComplete ? 'Yes' : 'No',
      }),
    };
    return new AirframeModel(data);
  }

  public serialize(): IAPIAirframe {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      id: this.id,
      statusId: this.status?.value,
      accessLevelId: this.accessLevel?.id,
      sourceTypeId: this.sourceType?.id || 1,
      aircraftVariationId: this.aircraftVariation?.id,
      serialNumber: this.serialNumber,
      manufactureDate: this.manufactureDate || null,
      temporaryEngineDate: this.temporaryEngineDate || null,
      crewSeatCap: Utilities.getNumberOrNullValue(this.crewSeatCap),
      paxSeatCap: Utilities.getNumberOrNullValue(this.paxSeatCap),
      noiseChapterId: this.noiseChapter?.id,
      airframeStatusId: this.airframeStatus?.id,
      rdbSpecs: this.rdbSpecs.serialize(),
      airframeMilitaryDesignationIds: this.militaryDesignations?.map(x => x.id),
      airworthinessRecentDate: this.airworthinessRecentDate || null,
      airworthinessCertificateDate: this.airworthinessCertificateDate || null,
      isVerificationComplete: this.isVerificationComplete.value,
    };
  }

  static deserializeList(apiDataList: IAPIAirframe[]): AirframeModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPIAirframe) => AirframeModel.deserialize(apiData)) : [];
  }

  // required in auto complete
  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
