export enum FLIGHT_PLAN_FILTERS {
  FORMAT = 'Format',
  ASSIGNMENT = 'Assignment',
  CONTACT_FOR_CHANGES = 'Contact for changes',
  NOTES = 'Notes',
  BUILT_BY = 'Built by',
  ACCOUNT = 'Account',
  REGISTRY = 'Registry',
  ACCOUNT_NAME = 'Account Name',
}
