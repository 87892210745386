
/* istanbul ignore next */
export const fields = {
  deliveryPackageType: {
    label: 'Delivery Package Options*',
    rules: 'required',
  },
  departure: {
    label: 'Departure*',
    rules: 'required',
  },
  destination: {
    label: 'Destination*',
    rules: 'required',
  },
  destinationAlternate: {
    label: 'Destination Alternate*',
    rules: 'required',
  },
  takeoffAlternate: {
    label: 'Takeoff Alternate*',
    rules: 'required',
  },
  reclearDestination: {
    label: 'Reclear Destination*',
    rules: 'required',
  },
  reclearAlternate: {
    label: 'Reclear Alternate*',
    rules: 'required',
  },
  etp: {
    label: 'ETP*',
    rules: 'required',
  },
  etops: {
    label: 'ETOPS*',
    rules: 'required',
  },
  pointOfSafeReturn: {
    label: 'Point-of-Safe Return*',
    rules: 'required',
  },
};


