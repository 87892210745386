export const fields = {
  name: {
    label: 'Name*',
    rules: 'required|between:1,50',
  },
  modelMakes: {
    label: 'Makes',
    value: [],
  },
};
