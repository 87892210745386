import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    flex: 1,
  },
  gridWrapper: {
    minHeight: 200,
  },
});
