import { Utilities } from '@wings-shared/core';
import { MenuItem } from '@wings-shared/layout';

export const sidebarMenu: MenuItem[] = [
  { to: '', title: 'Flight Plan Format', icon: 'AirplaneIcon' },
  { to: 'change-records', title: 'Change Record', icon: 'TriangleIcon' },
  { to: 'etp-scenario', title: 'ETP Scenario', icon: 'ClockIcon' },
  { to: 'etp-policy', title: 'ETP Policy', icon: 'EtpPolicyIcon' },
  { to: 'performance', title: 'Performance', icon: 'PerformanceIcon' },
  {
    to: 'aircraft-variation',
    title: 'Aircraft Variation',
    icon: 'AircraftVariationIcon',
  },
  {
    to: 'airframe',
    title: 'Airframe',
    icon: 'AirframeIcon',
  },
  {
    to: 'aircraft-registry',
    title: 'Aircraft Registry',
    isHidden: true,
    icon: 'AircraftVariationIcon',
  },
  {
    to: 'generic-registry',
    title: 'Generic Registry',
    icon: 'ListIcon',
  },
  {
    to: 'flight-planning-service',
    title: 'Flight Planning Services',
    icon: 'ListIcon',
  },
  {
    to: 'settings',
    title: 'Settings',
    icon: 'SettingIcon',
  },
];

export const updateAircraftSidebarOptions = (tabQuery: string): MenuItem[] =>
  Utilities.updateSidebarOptions(sidebarMenu, tabQuery);
