import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  paperSize: {
    width: 1150,
    height: '80%',
  },
  headerWrapper: {
    margin: 0,
    wordBreak: 'break-all',
  },
}));
