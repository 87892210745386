import { IAPIRDBSpecs } from '../Interfaces';
import { CoreModel, ISelectOption, modelProtection, SettingsTypeModel } from '@wings-shared/core';

@modelProtection
export class RDBSpecsModel extends CoreModel implements ISelectOption {
  rdbSpecsId: number = 0;
  acarsMsgDescription: string = '';
  acarsSoftwareVersion: SettingsTypeModel;
  acarsModel: SettingsTypeModel;
  acarsManufacturer: SettingsTypeModel;
  acarsMessageSet: SettingsTypeModel;
  fmsModel: SettingsTypeModel;
  fmsManufacturer: SettingsTypeModel;
  fmsSoftwareVersion: SettingsTypeModel;

  constructor(data?: Partial<RDBSpecsModel>) {
    super(data);
    Object.assign(this, data);
    this.acarsSoftwareVersion = new SettingsTypeModel(data?.acarsSoftwareVersion);
    this.acarsModel = new SettingsTypeModel(data?.acarsModel);
    this.acarsManufacturer = new SettingsTypeModel(data?.acarsManufacturer);
    this.acarsMessageSet = new SettingsTypeModel(data?.acarsMessageSet);
    this.fmsModel = new SettingsTypeModel(data?.fmsModel);
    this.fmsManufacturer = new SettingsTypeModel(data?.fmsManufacturer);
    this.fmsSoftwareVersion = new SettingsTypeModel(data?.fmsSoftwareVersion);
  }

  static deserialize(apiData: IAPIRDBSpecs): RDBSpecsModel {
    if (!apiData) {
      return new RDBSpecsModel();
    }
    const data: Partial<RDBSpecsModel> = {
      ...apiData,
      acarsSoftwareVersion: SettingsTypeModel.deserialize({
        ...apiData.acarsSoftwareVersion,
        id: apiData.acarsSoftwareVersion?.acarsSoftwareVersionId,
      }),
      acarsModel: SettingsTypeModel.deserialize({
        ...apiData.acarsModel,
        id: apiData.acarsModel?.acarsModelId,
      }),
      acarsManufacturer: SettingsTypeModel.deserialize({
        ...apiData.acarsManufacturer,
        id: apiData.acarsManufacturer?.acarsManufacturerReferenceId,
      }),
      acarsMessageSet: SettingsTypeModel.deserialize({
        ...apiData.acarsMessageSet,
        id: apiData.acarsMessageSet?.acarsMessageSetId,
      }),
      fmsModel: SettingsTypeModel.deserialize({
        ...apiData.fmsModel,
        id: apiData.fmsModel?.fmsModelId,
      }),
      fmsManufacturer: SettingsTypeModel.deserialize({
        ...apiData.fmsManufacturer,
        id: apiData.fmsManufacturer?.fmsManufacturerReferenceId,
      }),
      fmsSoftwareVersion: SettingsTypeModel.deserialize({
        ...apiData.fmsSoftwareVersion,
        id: apiData.fmsSoftwareVersion?.fmsSoftwareVersionId,
      }),
    };
    return new RDBSpecsModel(data);
  }

  public serialize(): IAPIRDBSpecs {
    return {
      id: this.id,
      acarsMsgDescription: this.acarsMsgDescription,
      acarsMessageSetId: this.acarsMessageSet.id,
      fmsSoftwareVersionId: this.fmsSoftwareVersion.id,
    };
  }

  static deserializeList(apiDataList: IAPIRDBSpecs[]): RDBSpecsModel[] {
    return apiDataList ? apiDataList.map((apiData: IAPIRDBSpecs) => RDBSpecsModel.deserialize(apiData)) : [];
  }

  // required in auto complete
  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
