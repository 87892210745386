import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
  },
  defaultWrapper: {
    marginLeft: 16,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
}));
