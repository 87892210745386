export enum MAIN_DESCENT {
  DRIFT_DOWN = 'Drift Down',
  INSTANTANEOUS = 'Instantaneous',
  FIXED = 'Fixed',
  LEVEL_OFF = 'Level Off',
}

export enum LEVEL_OFF {
  FIXED = 'Fixed',
  DRIFT_DOWN = 'Drift Down',
}

export enum ETP_ENGINE_TYPE {
  DECOMPRESSION = 'Decompression',
  DECOMP = 'Decomp/LoE',
}
