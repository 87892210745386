export enum AIRFRAME_FILTERS {
  SERIAL_NUMBER = 'Serial Number',
  SERIES = 'Series',
  AIRFRAME_STATUS = 'Airframe Status',
  AIRWORTHINESS_DATE = 'Airworthiness Date',
  VARIATION = 'Variation',
  REGISTRY = 'Registry',
  MTOW = 'MTOW',
  ICAO_TYPE = 'ICAO Type',
  MAKE = 'Make',
  MODEL = 'Model',
}
